module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-theme-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/@sentry/gatsby/gatsby-browser.js'),
      options: {"plugins":[],"environment":"production","tracesSampleRate":0.5,"dsn":"https://9ea542d0796940149a8240891a4d26ca@o411470.ingest.sentry.io/5330632"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Engimono Partner","short_name":"Engimono Partner","start_url":"/","background_color":"hsl(0,0%,100%)","theme_color":"hsl(360, 88%, 60%)","display":"minimal-ui","icon":"src/images/icon-round.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"e317844aaa4d6b658147dfdf5057664a"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
