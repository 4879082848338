import React, { createContext } from 'react'
import productSpec from '@msc-insure/product-spec'

const product = productSpec.getByProductId('MSC')

export const SiteContext = createContext(null)

export const wrapRootElement = ({ element }) => (
  <SiteContext.Provider value={{ product }}>
      {element}
  </SiteContext.Provider>
)

