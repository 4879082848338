const buttonBase = {
  borderRadius: '8px',
}

const buttons = {
  link: {
    ...buttonBase,
    background: 'transparent',
    appearance: 'none',
    cursor: 'pointer',
    fontFamily: 'inherit',
    fontWeight: 'inherit',
    lineHeight: 'inherit',
    color: 'primary',
    textDecoration: 'underline',
    textDecorationSkipInk: 'auto',
    border: 0,
  },
  primary: {
    ...buttonBase,
    fontFamily: 'body',
    fontWeight: 500,
    display: 'inline-flex',
    alignItems: 'center',
    color: 'white',
    bg: 'primary',
    border: '1px solid',
    borderColor: 'transparent',
    '& > svg': { marginLeft: '4px' },
  },
  secondary: {
    ...buttonBase,
    fontFamily: 'body',
    fontWeight: 500,
    display: 'inline-flex',
    alignItems: 'center',
    color: 'muted',
    bg: 'gray.0',
    border: '1px solid',
    borderColor: 'gray.2',
    '& > svg': { marginLeft: '4px' },
  },
  outline: {
    ...buttonBase,
    fontFamily: 'body',
    fontWeight: 500,
    display: 'inline-flex',
    alignItems: 'center',
    color: 'text',
    bg: 'transparent',
    border: '1px solid',
    borderColor: 'gray.1',
    '& > svg': { marginLeft: '4px' },
  },
  bare: {
    fontFamily: 'body',
    fontWeight: 500,
    bg: 'transparent',
    color: 'text',
    border: '1px solid',
    borderColor: 'transparent',
  },
}

const links = {
  regular: {
    variant: 'styles.a',
  },
  app: {
    textDecoration: 'none',
    color: 'primary',
    display: 'inline-flex',
    alignItems: 'center',
    transition: 'all 0.2s ease-in-out',
    '& > svg': { marginLeft: '2px' },
    '&:hover svg': {
      marginLeft: '4px',
    },
  },
  block: {
    display: 'block',
    color: 'inherit',
    textDecoration: 'none',
  },
}

module.exports = {
  useColorSchemeMediaQuery: false,
  useCustomProperties: true,
  colors: {
    primary: 'hsl(360, 88%, 60%)',
    focus: 'hsla(360, 88%, 60%, 0.2)',
    secondary: 'hsl(360, 10%, 60%)',
    text: 'hsl(360, 21%, 25%)',
    background: 'hsl(0,0%,100%)',
    muted: 'hsl(360, 10%, 60%)',
    gray: [
      'hsl(360, 18%, 97%)',
      'hsl(360, 14%, 93%)',
      'hsl(360, 12%, 85%)',
      'hsl(360, 10%, 75%)',
      'hsl(360, 10%, 64%)',
      'hsl(360, 10%, 52%)',
      'hsl(360, 10%, 38%)',
      'hsl(360, 12%, 24%)',
      'hsl(360, 14%, 12%)',
      'hsl(360, 18%, 8%)',
    ],
    primaries: [
      'hsl(360, 91%, 97%)',
      'hsl(360, 91%, 94%)',
      'hsl(360, 91%, 85%)',
      'hsl(360, 91%, 75%)',
      'hsl(360, 91%, 64%)',
      'hsl(360, 91%, 50%)',
      'hsl(360, 91%, 38%)',
      'hsl(360, 91%, 24%)',
      'hsl(360, 91%, 14%)',
      'hsl(360, 91%, 10%)',
    ],
    secondaries: [
      'hsl(360, 18%, 97%)',
      'hsl(360, 14%, 93%)',
      'hsl(360, 12%, 85%)',
      'hsl(360, 10%, 75%)',
      'hsl(360, 10%, 64%)',
      'hsl(360, 10%, 52%)',
      'hsl(360, 10%, 38%)',
      'hsl(360, 12%, 24%)',
      'hsl(360, 14%, 12%)',
      'hsl(360, 18%, 8%)',
    ],
    danger: {
      text: 'hsl(6, 84%, 46%)',
      background: 'hsl(8, 100%, 93%)',
    },
    warning: {
      text: 'hsl(36, 84%, 46%)',
      background: 'hsl(36, 100%, 93%)',
    },
    success: {
      text: 'hsl(126, 82%, 40%)',
      background: 'hsl(126, 70%, 94%)',
    },
    info: {
      text: 'hsl(360, 91%, 38%)',
      background: 'hsl(360, 91%, 94%)',
    },
  },
  fonts: {
    body: '"Alegreya Sans", sans-serif',
    heading: '"Alegreya Sans", Georgia, serif',
    smallCaps: '"Alegreya Sans SC", Georgia, serif',
    monospace: 'Menlo, monospace',
  },
  googleFonts: [
    {
      family: 'Alegreya Sans',
      variants: ['300', '300i', '500'],
      subsets: ['latin-ext'],
    },
    {
      family: 'Alegreya Sans SC',
      variants: ['300', '500'],
      subsets: ['latin-ext'],
    },
  ],
  fontSizes: [16, 18, 20, 25.008, 31.248, 39.056, 48.832, 61.04],
  space: [0, 4, 8, 16, 24, 32, 64, 128, 256, 512],
  breakpoints: ['40em', '56em', '64em'],
  radii: [0, 4, 8, 12],
  buttons,
  links,
  text: {
    base: {
      color: 'text',
      fontFamily: 'body',
      fontWeight: 'body',
      lineHeight: 'body',
    },
    mono: {
      color: 'text',
      fontFamily: 'monospace',
      fontSize: '13px',
      fontWeight: 'body',
      lineHeight: 'body',
    },
    label: {
      color: 'muted',
      fontSize: 1,
      fontWeight: 'bold',
      fontFamily: 'body',
    },
    paragraph: {
      fontFamily: 'body',
      fontWeight: 'body',
      lineHeight: 'body',
      maxWidth: 'measure',
      mt: '0.75em',
      mb: '0.75em',
    },
    fancy: {
      fontFamily: 'body',
      fontWeight: 'body',
      lineHeight: 'body',
      display: 'inline',
      '& + &:before': {
        content: '" ¶ "',
        color: 'primary',
        fontWeight: 'bold',
        fontFamily: 'heading',
      },
    },
    heading: {
      fontFamily: 'heading',
      fontWeight: 'heading',
      lineHeight: 'heading',
      letterSpacing: 'heading',
    },
    primary: {
      fontFamily: 'heading',
      fontWeight: 'heading',
      lineHeight: 'heading',
      letterSpacing: 'heading',
    },
    secondary: {
      color: 'primary',
      fontWeight: 'bold',
      fontFamily: 'body',
      lineHeight: 'heading',
      variant: 'text.smallCaps',
    },
    smallCaps: {
      fontFamily: 'smallCaps',
      textTransform: 'lowercase',
      // fontVariantCaps: 'all-small-caps',
      letterSpacing: 'smallCaps',
    },
    bold: {
      fontWeight: 'bold',
    },
  },
  cards: {
    primary: {
      borderRadius: 2,
      p: 4,
      boxShadow:
        'rgba(60, 66, 87, 0.11) 0px 6px 12px 0px, rgba(0, 0, 0, 0.11) 0px 2px 4px 0px',
    },
    border: {
      borderRadius: 2,
      p: 4,
      border: '1px solid',
      borderColor: 'gray.1',
    },
  },
  fontWeights: {
    body: 300,
    heading: 500,
    bold: 500,
  },
  lineHeights: {
    tight: 1,
    body: 1.4,
    heading: 1.125,
  },
  letterSpacings: {
    body: 'normal',
    heading: '-0.01em',
    smallCaps: '0.05em',
  },
  lists: {
    primary: {
      m: 0,
      p: 0,
      pl: '1em',
      mt: 2,
      listStyleType: 'none',
      '& > li::before': {
        content: '"•"',
        fontFamily: 'Georgia',
        color: 'primary',
        display: 'inline-block',
        width: '1em',
        marginLeft: '-1em',
      },
      '& > li': {
        mt: 1,
      },
      '& > li:first-of-type': {
        mt: 0,
      },
    },
  },
  forms: {
    naked: {
      appearance: 'none',
      padding: 0,
      border: 0,
      color: 'inherit',
      verticalAlign: 'middle',
      fontFamily: 'body',
      fontWeight: 'body',
      fontSize: 'inherit',
      lineHeight: 'body',
    },
    input: {
      appearance: 'none',
      padding: '0.25em 0.5em 0.2em 0.5em',
      border: '1px solid',
      bg: 'background',
      borderColor: 'gray.3',
      color: 'inherit',
      fontFamily: 'body',
      fontWeight: 'body',
      fontSize: 'inherit',
      lineHeight: 'body',
      borderRadius: 1,
      boxShadow: 'hsla(0,0%,0%,0.1) 0px 1px 2px inset',
      verticalAlign: 'middle',
      '&:focus': {
        outline: 'none',
        borderColor: 'primary',
        boxShadow: 'focus.normal',
      },
      '::placeholder': {
        color: 'gray.3',
      },
      '::selection': {
        bg: 'primaries.1',
      },
      '&[data-prefilled=true]': {
        bg: 'warning.background',
      },
      '&[aria-invalid=true]': {
        borderColor: 'danger.text',
        bg: 'danger.background',
      },
      '&[aria-invalid=true]:focus': {
        boxShadow: 'focus.invalid',
      },
      '&[type="password"]': {
        fontFamily: 'monospace',
        letterSpacing: '-0.05em',
        '::placeholder': {
          color: 'gray.2',
          fontFamily: 'body',
          letterSpacing: 0,
        },
      },
    },
    select: {
      appearance: 'none',
      padding: '0.25em 0.5em 0.2em 0.5em',
      border: '1px solid',
      bg: 'background',
      borderColor: 'gray.3',
      color: 'inherit',
      fontFamily: 'body',
      fontWeight: 'body',
      fontSize: 'inherit',
      lineHeight: 'body',
      borderRadius: 1,
      boxShadow: 'hsla(0,0%,0%,0.1) 0px 1px 2px inset',
      verticalAlign: 'middle',
      '&:focus': {
        outline: 'none',
        borderColor: 'primary',
        boxShadow: 'focus.normal',
      },
      '::placeholder': {
        color: 'gray.3',
      },
      '::selection': {
        bg: 'primaries.1',
      },
      '&[data-prefilled=true]': {
        bg: 'warning.background',
      },
      '&[aria-invalid=true]': {
        borderColor: 'danger.text',
        bg: 'danger.background',
      },
      '&[aria-invalid=true]:focus': {
        boxShadow: 'focus.invalid',
      },
      '&[type="password"]': {
        fontFamily: 'monospace',
        letterSpacing: '-0.05em',
        '::placeholder': {
          color: 'gray.2',
          fontFamily: 'body',
          letterSpacing: 0,
        },
      },
      pr: 5,
    },
    textarea: {
      appearance: 'none',
      padding: '0.25em 0.5em 0.2em 0.5em',
      border: '1px solid',
      bg: 'background',
      borderColor: 'gray.3',
      color: 'inherit',
      fontFamily: 'body',
      fontWeight: 'body',
      fontSize: 'inherit',
      lineHeight: 'body',
      borderRadius: 1,
      boxShadow: 'hsla(0,0%,0%,0.1) 0px 1px 2px inset',
      verticalAlign: 'middle',
      '&:focus': {
        outline: 'none',
        borderColor: 'primary',
        boxShadow: 'focus.normal',
      },
      '::placeholder': {
        color: 'gray.3',
      },
      '::selection': {
        bg: 'primaries.1',
      },
      '&[data-prefilled=true]': {
        bg: 'warning.background',
      },
      '&[aria-invalid=true]': {
        borderColor: 'danger.text',
        bg: 'danger.background',
      },
      '&[aria-invalid=true]:focus': {
        boxShadow: 'focus.invalid',
      },
      '&[type="password"]': {
        fontFamily: 'monospace',
        letterSpacing: '-0.05em',
        '::placeholder': {
          color: 'gray.2',
          fontFamily: 'body',
          letterSpacing: 0,
        },
      },
      py: 2,
    },
    slider: {
      bg: 'gray.1',
    },
    label: {
      variant: 'text.label',
    },
    labelRadio: {
      variant: 'text.label',
      color: 'text',
      borderBottom: '1px dotted',
      borderColor: 'gray.1',
      py: 2,
      '&:last-of-type': {
        borderBottom: 'none',
      },
    },
    labelCheckbox: {
      variant: 'text.label',
      color: 'text',
    },
  },
  fieldsets: {
    primary: {
      borderTop: '1px dashed',
      borderColor: 'gray.2',
      mb: [5, 5, 6],
    },
    secondary: {
      borderTop: '1px dashed',
      borderColor: 'gray.2',
      mb: [4, 4, 5],
    },
  },
  legends: {
    primary: {
      fontSize: [2, 2, 3],
      fontWeight: 'bold',
      mb: 3,
      p: 0,
      pr: 2,
      variant: 'text.smallCaps',
    },
    secondary: {
      fontSize: [1, 1, 2],
      fontWeight: 'bold',
      mb: 3,
      p: 0,
      pr: 2,
    },
  },
  sizes: {
    measure: '70ch',
    container: '100ch',
    icon: 20,
  },
  styles: {
    root: {
      margin: 0,
      bg: 'gray.0',
      overflowY: 'scroll',
      minHeight: '100vh',
      fontFamily: 'body',
      fontWeight: 'body',
      fontSize: 1,
      lineHeight: 'body',
      '& ::selection': {
        bg: 'primaries.1',
      },
      '& a[rel="noopener noreferrer"]:focus': {
        outline: 'none',
        bg: 'primaries.1',
      },
    },
    spinner: {
      color: 'primary',
    },
    a: {
      color: 'primary',
      cursor: 'pointer',
      textDecoration: 'underline',
      textDecorationSkipInk: 'auto',
      '& a:focus': {
        outline: 'none',
        bg: 'primaries.1',
      },
    },
    p: {
      variant: 'text.paragraph',
    },
    code: {
      fontFamily: 'monospace',
      fontSize: '0.7em',
      borderLeft: '2px solid',
      borderColor: 'gray.2',
      display: 'inline-block',
      px: 2,
      py: 1,
      bg: 'gray.1',
    },
    strong: {
      fontWeight: 'bold',
    },
    em: {
      fontStyle: 'italic',
    },
    h1: {
      variant: 'text.primary',
      fontSize: [4, 4, 5, 6],
    },
    h2: {
      variant: 'text.primary',
      fontSize: [3, 3, 4],
    },
    h3: {
      variant: 'text.primary',
      fontSize: [2, 2, 3],
    },
    h4: {
      variant: 'text.primary',
      fontSize: 2,
    },
    hr: {
      border: 0,
      borderBottom: '1px solid',
      borderColor: 'secondary',
      my: [3, 4, 4, 5],
    },
  },
  layout: {
    root: {
      bg: 'gray.0',
      minHeight: '100vh',
    },
    container: {
      px: [4, 4, 5, 6],
      maxWidth: 'container',
    },
    main: {},
    header: {
      py: [4, 4, 5, 6],
    },
    footer: {
      mt: [4, 5, 6],
      py: [5, 6],
      bg: 'gray.0',
    },
  },
  shadows: {
    lifted: {
      top: '0 3px 10px 0 hsla(0,0%,0%,0.1)',
    },
    focus: {
      normal: '0 0 0 0.175em hsla(360, 100%, 50%, 0.2)',
      invalid: '0 0 0 0.175em hsl(8, 100%, 93%)',
    },
  },
  alerts: {
    info: {
      fontWeight: 'body',
      bg: 'info.background',
      color: 'info.text',
    },
    muted: {
      fontWeight: 'body',
      color: 'text',
      bg: 'gray.1',
    },
    success: {
      fontWeight: 'body',
      bg: 'success.background',
      color: 'success.text',
    },
    danger: {
      fontWeight: 'body',
      bg: 'danger.background',
      color: 'danger.text',
    },
    warning: {
      fontWeight: 'body',
      bg: 'warning.background',
      color: 'warning.text',
    },
  },
  messages: {
    primary: {
      bg: 'gray.0',
      padding: 12,
      borderColor: 'primary',
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
    },
    secondary: {
      bg: 'gray.0',
      padding: 12,
      borderColor: 'secondary',
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
    },
  },
  zIndex: {
    focus: 1,
    toasts: 1000,
  },
}
